<template>
  <div id="root">
    <gmap-map
      :center="{ lng: 36.435430501859, lat: 32.143103745256 }"
      :zoom="12"
      style="width: 100%; height: 500px"
    >
      <gmap-polygon
        :paths="paths"
        :editable="true"
        @paths_changed="updateEdited($event)"
      >
      </gmap-polygon>
    </gmap-map>

    <ul v-if="edited" @click="edited = null">
      <li v-for="(path, i) in edited" :key="i">
        <ol>
          <li v-for="(point, x) in path" :key="x">
            {{ point.lat }}, {{ point.lng }}
          </li>
        </ol>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "Polygon",
  data: function() {
    return {
      edited: null,
      paths: [
        [
          {
            lng: 36.435430501859,
            lat: 32.143103745256
          },
          {
            lng: 36.435723385777,
            lat: 32.142743502839
          },
          {
            lng: 36.43622622921,
            lat: 32.143316921521
          },
          {
            lng: 36.436061363607,
            lat: 32.143591283718
          },
          {
            lng: 36.43599609666,
            lat: 32.143678308334
          },
          {
            lng: 36.435589961144,
            lat: 32.143257335219
          },
          {
            lng: 36.435430501859,
            lat: 32.143103745256
          }
        ]
      ]
    };
  },
  methods: {
    updateEdited(mvcArray) {
      let paths = [];
      for (let i = 0; i < mvcArray.getLength(); i++) {
        let path = [];
        for (let j = 0; j < mvcArray.getAt(i).getLength(); j++) {
          let point = mvcArray.getAt(i).getAt(j);
          path.push({ lat: point.lat(), lng: point.lng() });
        }
        paths.push(path);
      }
      this.edited = paths;
    }
  }
};
</script>

<style scoped></style>
